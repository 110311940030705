import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'components/ui/Container';

import * as Styled from './styles';

const Footer: React.FC = () => {
  const site = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          copyRights
        }
      }
    }
  `);

  const copyRights: string = site.site.siteMetadata.copyRights;

  return (
    <Styled.Footer>
      <Container>
        <Styled.Links>
          <Styled.Text>{copyRights}</Styled.Text>
          <Styled.Link href="/overview" rel="noreferrer noopener">
            会社概要
          </Styled.Link>
          <Styled.Link href="/commerce" rel="noreferrer noopener">
            特定商取引法に基づく表示
          </Styled.Link>
          <Styled.Link href="/privacy" rel="noreferrer noopener">
            プライバシーポリシー
          </Styled.Link>
        </Styled.Links>
      </Container>
    </Styled.Footer>
  );
};

export default Footer;
